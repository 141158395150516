.image_ia {
  border: 5px solid grey;

  &--ok {
    border: 5px solid green;
  }

  &--ko {
    border: 5px solid red;
  }
}