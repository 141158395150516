//@import './components/ChecklistAsset/ChecklistAsset';
//@import './components/ChecklistArchive/ChecklistArchive';
//@import './components/ChecklistPersonalArchive/ChecklistPersonalArchive';
//@import './components/ChecklistIndex/ChecklistIndex';
//@import './components/ChecklistModelsAssets/ChecklistsModelAssets';
//@import './components/ChecklistEdit/ChecklistEdit';
//@import './components/ChecklistPhotos/ChecklistPhotos';
//@import './components/ValidationComponent/ValidationComponent';
//@import './components/MultiFileComponent/MultiFileComponent';
//@import './components/ResetHoursComponent/ResetHoursComponent';
//@import './components/NumberCalculatorComponent/NumberCalculatorComponent';
//@import './components/ChecklistEdit/RunningTotals';
//@import './components/ChecklistEdit/SectionAttributes';
//@import './components/SetupComponent/SetupComponent';
@import 'react-calculator-input/lib/index.scss';
@import '~react-tippy/dist/tippy.css';
@import '~slick-carousel/slick/slick.css';

.numeric-input-component {
  .calculator-right {
    margin-left: 1rem;
  }
}

.ReactTable {
  &.mv4iot-fe-gia-table .rt-table,
  &.mv4iot-fe-gia-table .rt-th,
  &.mv4iot-fe-checklist-archive-table .rt-table {
    overflow: visible;
  }
}

/* ChecklistArchieve (non usato) */
.mv4iot-fe-checklist-archive-table {
  .-pagination .-previous,
  .ReactTable .-pagination .-next {
    width: 3rem !important;
  }
  .-pagination .-next {
    margin-left: 0 !important;
  }
}

/* ChecklistAsset (non usato) */
.checklist-asset-table {
  .btn-link {
    color: var(--gray-900);
  }
}

.cl-list .list-group-item {
  padding: 1rem;
}

/* ChecklistDaySummary */
.mv4iot-fe-checklist-summary-table {
  .-pagination .-previous,
  .ReactTable .-pagination .-next {
    width: 3rem !important;
  }
  .-pagination .-next {
    margin-left: 0 !important;
  }
}

.mv4iot-fe-checklist-summary-table-not-compiled {
  background-color: #fef6dd;
}

/* Checklist Edit (non usato) */
.cl-container {
  margin-bottom: 1rem;
  .cl-header {
    .mv-row {
      background: var(--gray-400);
    }
    padding: 1rem 0 0;
    .title {
      margin: 0;
      padding: 1rem;
    }
  }
  .cl-item-list {
    padding: 1rem;
    @media screen and (max-width: 767px) {
      padding: 0;
    }
    .mv-col {
      &:nth-child(even) {
        background: var(--gray-100);
      }
    .cl-item {

      padding: 1rem 0;
      .cl-item-description {
        width: 20%;
        .mv-button {
          padding-left: 0;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
          padding: 1rem;
          margin-bottom: 1rem;
        }
        p {
          margin: 0;
        }
      }
      .cl-item-controls {
        padding: 0 1rem;
        width: 80%;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        .btn-group {
          width: 100%;
          button {
            width: 50%;
            height: 4rem;
            &.hover-success {
              &:hover {
                background-color: var(--success);
                border-color: var(--success);
                color: var(--white);
              }
              &.active {
                background-color: var(--success);
                border-color: var(--success);
                color: var(--white);
              }
            }
            &.hover-danger {
              &:hover {
                background-color: var(--danger);
                border-color: var(--danger);
                color: var(--white);
              }
              &.active {
                background-color: var(--danger);
                border-color: var(--danger);
                color: var(--white);
              }
            }
            &.hover-secondary {
              &:hover {
                background-color: var(--secondary);
                border-color: var(--secondary);
              }
              &.active {
                background-color: var(--secondary);
                border-color: var(--secondary);
              }
            }
          }
          & + form {
            margin-top: 1rem;
          }
        }
        form {
          margin-left: 10%;
          text-align: left;
          label {
            font-size: 0.75rem;
          }
        }
      }
      .component-group.mandatory-components.has-not-optional-components {
        margin-bottom: -0.5rem;
      }
    }}
    &.big-text {
      .cl-item-description {
        font-size: 1.25rem;
      }
    }
    &.reduced-vertical-space {
      .cl-item {
        padding: 0.5rem 0;
      }
    }
    &.increased-component-widths {
      .cl-item-description {
        width: 16%;
        font-size: 100%;
      }
      .cl-item-controls {
        width: 84%;
      }
    }
    &.hide-component-titles {
      .cl-item-controls {
        label {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .cl-header {
      [class^='col-'] {
        background-color: transparent;
        color: var(--grey-900);
        border-bottom: 2px solid var(--success);
        margin: 0;
        margin-top: .4rem;
      }
      padding: 0 .5rem 0;
      .title {
        margin: 0;
        padding: 1rem;
        text-align: center;
      }
    }
    .cl-item {
      padding: 1rem .5rem !important;
    }
  }
}


/* Checklist Index (non usato) */
.checklist-index-table {
  .btn-link {
    color: var(--gray-900);
  }
}

/* ChecklistPersonalArchieve (non usato) */
.mv4iot-fe-checklist-archive-table {
  .-pagination .-previous,
  .ReactTable .-pagination .-next {
    width: 3rem !important;
  }
  .-pagination .-next {
    margin-left: 0 !important;
  }
}

/* ChecklistPhoto (non usato) */
$slideImageHeight: 200px;
$slideArrowSize: 30px;
$modalImageHeight: 80vh;

.mv4iot-fe-checklist {
  header {
    .react-datepicker-wrapper,
    .react-datepicker__input-container {
      width: 100%;
    }
  }

  .checklist-photos-carousel {
    margin-bottom: 2rem;

    .checklist-photo-slide {
      padding: 0 0.5rem;

      &:not(.disabled) {
        cursor: pointer;
      }

      &.disabled .checklist-photo-image {
        background: lightgrey;
      }
    }

    .checklist-photo-image {
      width: 100%;
      height: $slideImageHeight;
    }

    div.checklist-photo-image {
      background: lightgrey;

      .svg-inline--fa {
        color: grey;
      }
    }

    img.checklist-photo-image {
      object-fit: cover;
    }

    .slick-slider .slick-arrow {
      width: $slideArrowSize;
      height: $slideImageHeight;

      &:not(.slick-disabled) {
        background: #eee;
      }

      &.slick-disabled {
        color: darken(lightgrey, 0.5);
      }
    }
  }

  .checklist-photos-modal.modal-dialog {
    max-width: 70vw;

    .modal-content {
      background-color: #222;
      color: white;

      .modal-header {
        border-bottom: none;

        .close {
          color: white;
        }
      }

      .text-muted {
        color: lightgrey !important;
      }
    }

    .checklist-photo-image {
      display: flex;
      width: 100% !important;
      height: $modalImageHeight !important;
      justify-content: center;
      align-items: center;

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }

    .slick-arrow {
      width: $slideArrowSize;
      height: $modalImageHeight;

      &.slick-disabled {
        cursor: default;
        color: #444;
      }

      &:not(.slick-disabled):hover {
        color: lighten(lightgrey, 0.5);
      }

      &.slick-prev {
        text-align: left;
      }

      &.slick-next {
        text-align: right;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .modal-dialog {
      width: 100%;
      height: 100%;
      max-width: none;
      margin: 0;
    }

    .modal-content {
      margin: 0;
      border-radius: 0;
      height: 100%;
    }
  }

  .slick-slider {
    display: flex;
    width: 100%;

    &.slick-no-arrows {
      padding: 0 $slideArrowSize;
    }

    .slick-list {
      width: 100%;
    }

    .slick-track {
      margin-left: 0;
    }

    .slick-arrow {
      background: none;
      border: none;
      outline: none;
      padding: 0;
      color: grey;
      cursor: pointer;

      &:not(.slick-disabled):hover {
        color: black;
      }

      &.slick-disabled {
        cursor: default;
      }
    }
  }
}

/* ChecklistModelAssets (non usato) */
.checklist {
  &-title {
    width: 80%;
    h5 {
      font-size: 1.2rem;
      line-height: 1;
    }
    p {
      line-height: 1;
      margin-top: .8rem;
    }
  }

  &-arrow {
    width: 15%;
  }
}
@media screen and (max-width: 767px) {
  .cl-list {
    padding-left: .5rem;
    padding-right: .5rem;
  }
}


/* ChecklistTodaySummery */
.mv4iot-fe-checklist-summary-table {
  .-pagination .-previous,
  .ReactTable .-pagination .-next {
    width: 3rem !important;
  }
  .-pagination .-next {
    margin-left: 0 !important;
  }
}

.mv4iot-fe-checklist-summary-table-not-compiled {
  background-color: #fef6dd;
}


/* MultiFileComponent (non usato) */
.multi-file-component {
  .hidden {
    display: none;
  }
  .multi-file-thumb {
    position: relative;
    img {
      max-height: 4rem;
    }
    button {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      font-size: 60%;
      padding: 0.125rem 0.25rem;
      &:hover {
        border-color: var(--white);
        color: var(--white);
        background-color: var(--danger);
      }
    }
  }
}

/* NumberCalculatorComponent (non usato) */
.number-calculator-component {
  label {
    font-weight: 300;
    color: var(--gray-600);
    font-size: 0.9rem;
    margin-bottom: 0.125rem;
  }
  .produced-bales {
    min-width: 60%;
    @media screen and (max-width: 767px) {
      min-width: 100%;
    }
    .input-group {
      position: relative;
      width: 4.5rem;
      margin-right: 0.25rem;
      &:nth-last-child(-n + 2):not(.disabled) {
        width: 6rem;
      }
      &:last-child:not(.disabled) {
        margin-right: 0;
      }
    }
    .input-group-append {
      position: absolute;
      right: 0;
      .btn-outline-secondary {
        &.text-success:hover {
          background-color: var(--success);
          border-color: var(--success);
          color: var(--white) !important;
        }
        &.text-danger:hover {
          background-color: var(--danger);
          border-color: var(--danger);
          color: var(--white) !important;
        }
      }
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .pressed-again-bales,
  .to-reselect-bales,
  .total {
    .form-group {
      width: 5rem;
      margin-bottom: 0;
    }
    .form-text {
      margin-top: 0.125rem;
    }
  }
}


/* ResetHoursComponent (non usato) */
.mv4iot-fe-checklist-reset-hours-table {
  .-pagination .-previous,
  .ReactTable .-pagination .-next {
    width: 3rem !important;
  }
  .-pagination .-next {
    margin-left: 0 !important;
  }
}

.modal-body-asset-hours {
  .form-group {
    width: 100%;
  }
}

/* RunningTotals (non usato) */
.running-totals {
  padding-right: 3.25rem !important;
}


/* SectionAttributes (non usato) */
.section-attributes {
  padding-right: 3.3rem;
}


/* Setup Compoenent (non usato) */
.checklist-setup-status {
  color: #fff;
  padding: 0 8px;
  font-size: 0.9rem;

  &.checklist-setup-status-to-be-configured {
    background-color: #FFA500;
  }

  &.checklist-setup-status-active {
    background-color: #20B2AA;
  }

  &.checklist-setup-status-deactivated {
    background-color: #aaa;
  }

  &.checklist-setup-status-to-be-validated {
    background-color: #7191BF;
  }

  &.checklist-setup-status-undefined {
    background-color: #e31b1b;
  }
}
.checklist-model-actions button {
  font-size: 1.5rem;
  line-height: 1.5rem;
  display: inline-block;
  width: 40px;
}


/* Validation Component (non usato) */
.validation-component {
  margin: 1rem 0;
  padding: 1rem;
  border-top: 1px solid var(--gray-300);
  border-bottom: 1px solid var(--gray-300);
  .radio-component {
    .btn-group {
      width: 100%;
      button {
        width: 50%;
        height: 5.4rem;
        &.btn-outline-secondary {
          border-color: var(--gray-300);
        }
        &.hover-success {
          &:hover {
            background-color: var(--success);
            border-color: var(--success);
          }
          &.active {
            background-color: var(--success);
            border-color: var(--success);
          }
        }
        &.hover-danger {
          &:hover {
            background-color: var(--danger);
            border-color: var(--danger);
          }
          &.active {
            background-color: var(--danger);
            border-color: var(--danger);
          }
        }
        &.hover-secondary {
          &:hover {
            background-color: var(--secondary);
            border-color: var(--secondary);
          }
          &.active {
            background-color: var(--secondary);
            border-color: var(--secondary);
          }
        }
      }
    }
  }
  .text-component {
  }
  .file-component {
    img {
      max-height: 5.4rem;
    }
  }
}

.calculator-wrapper {
  right: 0;
}

