.mv4iot-fe-mcs {
  @import '~react-datepicker/dist/react-datepicker.min.css';
  @import 'balloon-css/src/balloon.scss';

  header {
    h1 {
      font-size: 1.5rem;
      letter-spacing: -0.025em;
      margin: 0;
    }

    h2 {
      font-size: 1rem;
      color: var(--gray-600);
    }
  }

  .table-loader {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    transition: all 0.3s ease;
    z-index: -1;
    opacity: 0;
    pointer-events: none;

    &.active {
      opacity: 1;
      z-index: 2;
      pointer-events: all;
    }

    svg {
      position: absolute;
      top: 44%;
      left: 48%;
      color: var(--gray-300);
    }

    &.polling {
      background: transparent !important;

      .polling-text {
        margin: 0.35rem 1.75rem 0 0;
        color: var(--gray-600);
      }

      svg {
        top: 0.5rem !important;
        right: 0.5rem !important;
        left: unset;
        color: var(--gray-600);
      }
    }
  }

  .react-datepicker__input-container {
    display: block;
  }

  .react-datepicker__close-icon {
    position: absolute;
    top: 1.25rem;
    right: 0;
  }

  #forklift-driver-or-truck-anomalies-note {
    border-radius: 5px;
    background: #fdb0b0cc;
    margin-right: 5px;
    margin-left: -7px;
    input,
    textarea {
      background-color: transparent;
    }
    > div {
      padding-right: 5px;
    }
    > label {
      padding-left: 10px;
    }
  }

  // Add Cargo Modal
  .datepicker[readonly] {
    background-color: var(--white);
  }

  // Close Day
  .close-day-table {
    .status-reschedule-mandatory {
      border-left: 0.5rem solid #f3c944;
      background-color: #fff8dd !important;
      border-bottom: 1px solid rgba(#f3c944, 0.2);
      // border-right: 0.5rem solid #f4ca33;
    }

    .status-reschedule-optional {
      border-left: 0.5rem solid #33acfa;
      background-color: #ddf0fd !important;
      border-bottom: 1px solid rgba(#33acfa, 0.2);
      // border-right: 0.5rem solid #f4ca33;
    }

    .status-rescheduled {
      border-left: 0.5rem solid #3dca3f;
      background-color: #e3f6e3 !important;
      border-bottom: 1px solid rgba(#3dca3f, 0.2);
      // border-right: 0.5rem solid #33cc33;
    }
  }

  .close-day-h3 {
    font-size: 1.25rem;
  }

  // Close day button
  .close-day-button {
    min-width: 124px;
  }

  // Close day Legend
  .close-day-legend {
    .item {
      min-width: 18rem;

      label {
        color: var(--gray-600);
        font-size: 0.8rem;
      }

      .square {
        width: 1.6rem;
        height: 0.8rem;
        margin: 0.25em;
        padding: 0;
        border-radius: 0;

        &.rescheduled {
          background: #33cc33;
        }

        &.reschedule-optional {
          background: #28aafd;
        }

        &.reschedule-mandatory {
          background: #f4ca33;
        }
      }
    }
  }

  // Legend
  .legend {
    .item {
      min-width: 6rem;

      label {
        color: var(--gray-600);
        font-size: 0.8rem;
      }

      .square {
        width: 1.6rem;
        height: 0.8rem;
        margin: 0.25em;
        padding: 0;
        border-radius: 0;

        &.success {
          background: #33cc33;
        }

        &.alert {
          background: #cc3333;
        }

        &.warning {
          background: #28aafd;
        }

        &.light-warning {
          background: #f4ca33;
        }

        &.dark-warning {
          background: #ac74dc;
        }

        &.secondary {
          background: #9c9c9c;
        }
      }
    }
  }

  // Past
  .concluded-cargos,
  .canceled-cargos,
  .unprocessed-cargos,
  .rescheduled-cargos {
    margin-top: 3rem;

    h3 {
      font-size: 1.25rem;
    }
  }

  // ProgressiveNumberCell

  .progressive-number-cell {
    border-radius: 5px;
    padding-top: 0rem;
    padding-bottom: 0rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    font-weight: 400;
    background-color: var(--white);
    color: var(--black);
    font-size: large;
    box-shadow: -1px 1px;
  }

  // Today
  .ReactTable {
    .rt-table,
    .rt-tbody {
      overflow-x: hidden;
    }
  }

  .status-pending_arrival {
    border-left: 0.5rem solid #3dca3f;
    background-color: #e3f6e3 !important;
    border-bottom: 1px solid rgba(#3dca3f, 0.2);
    // border-right: 0.5rem solid #33cc33;
  }

  .status-in_queue,
  .status-in_load_queue,
  .status-in_unload_queue {
    border-left: 0.5rem solid #33acfa;
    background-color: #ddf0fd !important;
    border-bottom: 1px solid rgba(#33acfa, 0.2);
    // border-right: 0.5rem solid #f4ca33;
  }

  .status-under_processing,
  .status-under_load_processing,
  .status-under_unload_processing {
    border-left: 0.5rem solid #9c9c9c;
    background-color: #f4f4f4 !important;
    border-bottom: 1px solid rgba(#9c9c9c, 0.2);
    // border-right: 0.5rem solid #f4ca33;
  }

  .status-requeued_with_priority,
  .status-requeued_with_priority_load,
  .status-requeued_with_priority_unload {
    border-left: 0.5rem solid #ab77d9;
    background-color: #e3cef5 !important;
    border-bottom: 1px solid rgba(#ab77d9, 0.2);
    // border-right: 0.5rem solid #cc33cc;
  }

  .status-blocked_for_irregularities {
    border-left: 0.5rem solid #ca3538;
    background-color: #ffdcdc !important;
    border-bottom: 1px solid rgba(#ca3538, 0.2);
    // border-right: 0.5rem solid #cc3333;
  }

  .status-ready_to_leave,
  .status-ready_to_transfer {
    border-left: 0.5rem solid #f3c944;
    background-color: #fff8dd !important;
    border-bottom: 1px solid rgba(#f3c944, 0.2);
  }

  .concluded-cargos {
    margin-top: 1.5rem;

    h3 {
      font-size: 1.25rem;
    }
  }

  // UpperPageActions
  .upper-page-actions {
    margin: 0 0 2rem;
  }

  .img-container {
    position: relative;
    width: 100%;
    height: 200px;
    cursor: pointer;
  }
  .img-container img {
    display: block;
    width: 100%;
    object-fit: cover;
    height: 200px;
  }
  .img-container button {
    position: absolute;
    top: 5%;
    right: 5%;
    opacity: 1;
  }
}

.modal-body .react-datepicker-wrapper {
  display: block;
}

.mv4iot-fe-mcs .input-group .react-datepicker-wrapper {
  width: auto;
}

.tippy-tooltip-content hr {
  display: block;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0.5em 0;
  padding: 0;
}

.rt-table {
  width: 100%;
  overflow-x: auto;
}
